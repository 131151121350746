<template>
    <el-dialog :visible.sync="show" append-to-body width="35%">
        <!-- <div class='login'> -->
            <transition name='slide-fade'>
                <el-card class="box-card login-box" v-if="show" v-loading="loading">
                    <el-form @submit.prevent="login()" >
                        <!-- <img src="../assets/logo.png" class="img-responsive img-logo" /> -->
                        <h5 style="text-align:center">Validare</h5>
                        <hr/>
                        
                        <el-form-item >
                            <el-input type="password" v-model="password" placeholder='Parola dvs'></el-input>
                        </el-form-item>
                        
                        <el-row :gutter='15' class='butoane'> 
                            <el-col :span="8">
                                <el-button class='full-width' @click='add("1")'>1</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button class='full-width'  @click='add("2")'>2</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button  class='full-width'  @click='add("3")'>3</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button class='full-width'  @click='add("4")'>4</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button class='full-width'  @click='add("5")'>5</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button  class='full-width'  @click='add("6")'>6</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button class='full-width'  @click='add("7")'>7</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button class='full-width'  @click='add("8")'>8</el-button>
                            </el-col>
                            <el-col :span="8">
                                <el-button  class='full-width'  @click='add("9")'>9</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button  class='full-width'></el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button  class='full-width'  @click='add("0")'>0</el-button>
                            </el-col>

                            <el-col :span="8">
                                <el-button  class='full-width'  @click='del()'> DEL </el-button>
                            </el-col>
                        </el-row>


                        <el-col :span="24">
                            <el-form-item>
                                <el-button type="primary" @click="login()" class='full-width' > Login </el-button>
                            </el-form-item>
                        </el-col>

                    </el-form>
                </el-card>
            </transition>
        <!-- </div> -->
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    export default {
        name: "login",
        extends: BasePage, 
        data () {
            return {
                show: false,
                loading: false,
                password: ""
            }
        },
        methods: {
            showMe(){
                this.show = true
            },
            async login(){
                var response = await this.post("clienti/check_pin",{pin:this.password})
                
                if(!response.Error){
                    this.$message({type:'success',message:response.Mesaj})  
                    this.show = false
                    this.$emit('logged')                  
                    this.password = ''
                }                
                else    
                    this.$message({type:'warning',message:"Parola nu corespunde"})
            },            
            add(nr){
                this.password += nr;
            },
            del(){
                if( this.password.length > 0 )
                {
                    this.password = this.password.toString().substring( 0, this.password.length - 1 );
                }
            }
        },
        mounted: function() {
            
        }
    };
</script>

<style lang="less" scoped>

    .img-logo {
        max-height: 40px;
    }
    .login-box {
        margin: 50px auto;
        width: 320px;
    }

    .slide-fade-enter-active {
        transition: all 1.3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateY(250px);
        opacity: 0;
    }
    .butoane{
        button
        {
            height: 50px;
            margin: 10px 0px;
        }
    }
</style>
