
<template>
    <el-autocomplete 
        class="full-width" 
        v-model="NumeProdusMutable" 
        placeholder="Cauta client"
        :trigger-on-focus="TriggerFocus"
        :fetch-suggestions="querySearch" 
        @select="handleSelectClient">      
        <template slot-scope="{ item }">
            <span class="nume">{{ item.Nume }}</span>
            <span v-if="arataInfo" style="float:right; color: #8492a6; font-size: 13px">{{item.Info}}</span>
        </template>
    </el-autocomplete>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
    name: "SelectClient",
    extends: BasePage,
    props: {
        value: {
            required: true,
        },
        IdClient:{
            required:false
        },
        TriggerFocus:{
            required:false,
            default:false
        },
        forcedByIdMasina:{
            type: String,
            required:false
        },
        arataInfo:{
            type:Boolean,
            default:false,
            required:false
        }
    },
    computed: {
        NumeProdusMutable: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    watch: {
        NumeProdusMutable: function(newVal) {
            this.$emit('input', newVal)
        },
        NumeClient: function(newVal) {
            this.$set(this, 'NumeProdusMutable', newVal)
        }
    },
    data () {
        return {
            Results: [],
            loading : true,
            NumeClient:''       
        }
    },
    methods: {

        async querySearch(queryString, cb) {
            if (!queryString) {
                queryString = ''
            }
            
            var response = await this.post('clienti/get_clienti', {
                queryString: queryString,
                IdMasina: this.forcedByIdMasina ? this.forcedByIdMasina : null
            })
            cb(response.Products)
        },
        handleSelectClient(item){
            var produsObj           = item.Client
            this.lastSelected       = produsObj
            this.NumeProdusMutable  = produsObj.Nume
            
            this.$emit('update:IdClient',produsObj.Id)
            this.$emit('selectie', produsObj)
        }
    },
    mounted(){
    }
};
</script>

<style lang="less" >

   .margin-bottom30 {
      margin-bottom:30px;
    }

</style>
