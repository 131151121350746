<template>
    <el-dialog title="Clienti" :visible.sync="showPopup" class="my-dialog-class" append-to-body :show-close='false' :close-on-click-modal='false' width="80%">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>                
                    <el-form-item label='Nume' prop="Nume">
                        <el-input name="nume"  class='full-width' v-model='selectedObject.Nume' />
                    </el-form-item>
                </el-col>
               
                <el-col :md='8'>  
                    <el-col style="margin-top:9px" :span="4">
                        <span >Email</span>
                    </el-col>                 
                    <el-button style="margin-top:7px;" size="mini" @click="completeazaEmail('@gmail.com')">@gmail.com</el-button>
                    <el-button style="margin-left:-1px;" size="mini" @click="completeazaEmail('@yahoo.com')">@yahoo.com</el-button>
                    <el-button style="margin-left:-1px;" size="mini" @click="completeazaEmail('@hotmail.com')">@hotmail.com</el-button>
                                    
                    <el-form-item :error="validateEmail()">                        
                        <el-input style="margin-top:23px" class='full-width' v-model='selectedObject.Email' />
                    </el-form-item>
                </el-col>

                <el-col :md='8'>
                    <el-form-item label='Telefon' prop="Telefon">
                        <el-input  class='full-width' v-model='selectedObject.Telefon' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-row style="margin-top: 10px;">
                    <div >Data Nasterii</div> 
                    </el-row>
                    <el-row :gutter="15" style="margin-top: 27px;">
                        <el-col :md="8" :xs="8">
                            <el-form-item >
                                <el-select  placeholder="Zi" v-model="selectedObject.Zi" filterable>
                                    <el-option v-for="(item,index) in DataNasterii.Zi" :key="index" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>
                            
                        </el-col>
                        <el-col :md="8" :xs="8">
                            <el-form-item >
                                <el-select placeholder="Luna" v-model="selectedObject.Luna" filterable>
                                    <el-option v-for="(item,index) in DataNasterii.Luna" :key="index" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>                        
                        </el-col>
                        <el-col :md="8" :xs="8">
                            <el-form-item >
                                <el-select placeholder="An" v-model="selectedObject.An" filterable>
                                    <el-option v-for="(item,index) in DataNasterii.An" :key="index" :value="item" :label="item"></el-option>
                                </el-select>
                            </el-form-item>                        
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Nume firma' >
                        <el-input  class='full-width' v-model='selectedObject.NumeFirma' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Cui' >
                        <el-input class='full-width' v-model='selectedObject.Cui' />
                    </el-form-item>
                </el-col> 
                
                 <el-col>
                    <el-card shadow="never">
                        <div slot="header" class="clearfix">
                            <span>Cerere de oferta</span>
                            <el-checkbox style="margin-left:20px" v-model='selectedObject.AreCerereOferta' true-label='1' false-label='0'> DA </el-checkbox>      
                        </div>
                        <el-col :md='8'>
                            <el-form-item label='Masina' >
                                <el-select v-model="idMasina" filterable :disabled="selectedObject.AreCerereOferta=='0'" class='full-width'>
                                    <el-option v-for="item in info.masini" :key="item.IdMasina" :value="item.IdMasina" :label="item.Label"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :md='16'>
                            <el-form-item label='Text'>
                                <el-input :disabled="selectedObject.AreCerereOferta=='0'" type="textarea" autosize :autosize="{ minRows: 3, maxRows: 6}" v-model='selectedObject.DescriereCerereOferta' > </el-input>
                            </el-form-item>
                        </el-col>
                    </el-card>
                 </el-col>

                <!-- upload -->                 
                <el-col :span="24" v-if="$este_tip_utilizator('office') || $este_tip_utilizator('admin')">
                    <el-form-item label='Upload talon' >                                               
                        <el-upload
                            :action="baseUrl + 'clienti/upload_talon'" 
                            ref="upload"                                        
                            :on-success="handleUploadSuccess_talon"                            
                            >
                            <el-button size="small" type="primary">Upload</el-button>
                        </el-upload>                                                                                                                                       
                    </el-form-item>    
                    <div class="attachment-box" v-if="isImage=='1' && isFile=='0'">
                        <a :href="selectedObject.uploadPreview_talon" target="_blank">
                            <img class="img-responsive" style='width: auto; max-width: 200px; max-height: 100px' :src="selectedObject.uploadPreview_talon" :title="selectedObject.Talon"/>
                        </a>
                        <div class="title">{{selectedObject.Talon}}</div>
                    </div>                           
                    <div class="attachment-box" v-if="isImage=='0' && isFile=='1'">
                        <a :href="selectedObject.uploadPreview_talon" target="_blank"><i class="el-icon-document"></i></a>
                        <div class="title">{{selectedObject.Talon}}</div>
                    </div>  
                </el-col>                          
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')" > Renunta  </el-button>
			<el-button :disabled="saveDisabled && $este_tip_utilizator('mecanic')" type="primary" @click="save()" > Salveaza </el-button>
			<el-button v-if="$este_tip_utilizator('mecanic')" type="primary" @click="checkPin()" > Salveaza fara e-mail </el-button>
        </span>

        <login ref="login" @logged="save"/>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import Login from '@/components/Login';
    import { now } from 'moment';
 
    export default {
        name: "Clienti_dialog",
        extends: BasePage,     
        components: {
            'login': Login
        },
        watch:{
            'selectedObject.Email'(val){
                if(val.length)
                    this.esteEmailGol = false
            }
        },
        computed: {       
            saveDisabled(){
                let ret = true;

                if(this.selectedObject.Nume !='' && this.selectedObject.Email !='' && this.selectedObject.Telefon !='') 
                    ret = false

                return ret
            },
            isEmailRequired(){
                if(!this.$este_tip_utilizator('mecanic'))
                    return ''
                else
                    return 'Email'
            }
            
        },   
        data () {
            var checkEmail = (rule, value, callback) => {
                const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
               
                setTimeout(() => {
                    if (mailReg.test(value)) {                        
                        callback()
                    } else {
                        callback(new Error('Adresa e-mail incorecta'))
                    }
                }, 100)                
            }
            var checkPhone = (rule, value, callback) => {
                const phoneReg = /^[0]\d{9}$/
                if (!value) {
                    callback(new Error('Campul este obligatoriu'))
                }
                setTimeout(() => {
                        // Number.isInteger is a method for es6 to verify whether the number is an integer. The actual input number is always recognized as a string.
                        // So add a + in front to implement implicit conversion
            
                if (!Number.isInteger(+value)) {
                    callback(new Error('Campul poate contine doar numere'))
                } else {
                    if (phoneReg.test(value)) {
                    callback()
                    } else {
                        callback(new Error('Campul este incorect'))
                    }
                }
                }, 100)
            }

            return {
                baseUrl:'',
                showPopup: false,
                mode: 'add',
                info: {masini:[]},
                selectedObjectTemplate: {
                    Nume: '' , Email: '' , Telefon: '' , Sex: '' , Zi:'', Luna:'', An:'' , NumeFirma: '' , Cui: '', Talon:'', uploadPreview_talon:'', DescriereCerereOferta:'', AreCerereOferta:'0' },
                selectedObject: {},
                DataNasterii: {Zi:[],Luna:[],An:[]},                                    
                rules: {
                    Nume:       [ { required: true, message: 'Campul este obligatoriu' } ],                          
                    Email:      [ { validator: checkEmail, trigger: ['blur', 'change'] }],          
                    // Telefon:    [ { required: true, validator: checkPhone, trigger: ['blur', 'change'] } ], 
                    Telefon:    [ { required: true, trigger: ['blur', 'change'] } ], 
                    Sex:        [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Zi:         [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Luna:       [ { required: true, message: 'Campul este obligatoriu' } ], 
                    An:         [ { required: true, message: 'Campul este obligatoriu' } ],                    
                },
                show_error: false,
                dialogImageUrl: '',
                dialogVisible: false,
                isFile:false,
                isImage:false,
                esteEmailGol:true,
                mesajEmail: checkEmail,   
                idMasina:''             
            }
        },
       
        methods: {
            show_me: async function( id ) {                
                this.selectedObject     = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
                this.arataEroareNume    = false
                this.show_error         = true;
                this.showPopup          = true;
                this.isFile             = false
                this.isImage            = false
                this.setDataNasterii()
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("clienti/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;     
                    this.info.masini    = result.Item.Masini   

                    let ext             = this.getExtension(this.selectedObject.Talon)
                    this.setType(ext)                                                            
                }
            },
            async get_info(){               
                var response = await this.post("clienti/get_info_for_dialog" );
            },
            async checkPin(){
                this.selectedObject.Email = ' '; 
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        this.$refs.login.showMe();
                    }
                    else 
                        console.log('eroare validare formular!')
                })
            
            },
            validateEmail(){
                const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
                if (mailReg.test(this.selectedObject.Email) || this.selectedObject.Email == '')
                    return '';
                else
                    return 'Adresa e-mail incorecta';
            },
            save: async function() {
               
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {                                              
                        await this.post("clienti/save", { mode: this.mode, object: this.selectedObject, idMasina: this.idMasina } );
                        // this.$refs.upload.submit();
                        this.resetForm()
                        this.$emit("save");
                    } 
                    else 
                        console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields() 
				this.showPopup = false
            },           
            async existaNume(){
                var response = await this.post('clienti/verifica_nume', {item: this.selectedObject})
                if(response.Error)
                    return true
                else
                    return false

            },
            setDataNasterii(){
                let z
                let a
                let l = ['ianuarie','februarie','martie','aprilie','mai','iunie',
                        'iulie','august','septembrie','octombrie','noiembrie','decembrie']

                for(z=1;z<32;z++) {
                    if(z.toString().length < 2) z='0'+z
                    this.DataNasterii.Zi.push(z)
                }
                for(a=1950; a<=2020; a++) this.DataNasterii.An.push(a)
                l.forEach(e => this.DataNasterii.Luna.push(e));                                             
            },
            handleUploadSuccess_talon(response){
                this.selectedObject.Talon = response.FileName;
                this.selectedObject.uploadPreview_talon  = response.Url;
            },
            async on_upload_complete(response, file, fileList){
                this.selectedObject.Talon = response.FileName;
                this.selectedObject.uploadPreview_talon  = response.Url;
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            getExtension(str){
                return str.split('.')[1];
            },
            setType(extension){
                var pictures    = ["jpg","png","jpeg","gif"];
                var pdf         = ["pdf"];
                
                if(pictures.includes(extension))
                    this.isImage = true
                if(pdf.includes(extension))
                    this.isFile = true
            },
            completeazaEmail(string){                
                var splitstring = this.selectedObject.Email.split('@');
                this.selectedObject.Email = splitstring[0] + string;

                console.log(splitstring);
            }           
        },
        mounted: function() {   
            this.baseUrl = settings.BASE_URL;        
            this.get_info();            
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .telefon-client:before {        
        content: '*';
        color: #F56C6C;
        margin-right: 4px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .attachment-box{
        margin-top: 25px;
        border: 1px dotted #DCDCDC;
        padding: 25px;
        text-align: center;
        overflow: hidden;
        a{
            font-size: 100px;
            height: 140px;
            display: block;
        }        
        .title{
            height: 20px;
            overflow: hidden;
        }
    }

</style>