<template>
    <el-dialog class='my-dialog-class' title="Masini" :visible.sync="showPopup" append-to-body :show-close='false' :close-on-click-modal='false' width="80%">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >                
                <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-form-item label='Numar' prop="Numar" ref="masina">
                        <el-input  class='full-width' v-model='selectedObject.Numar'/>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-form-item label='Marca' prop="Marca">
                        <el-input  class='full-width' v-model='selectedObject.Marca'/>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-form-item label='Cat. roata' prop="IdCategorieRoata">
                        <el-select v-model='selectedObject.IdCategorieRoata'  class='full-width'>
                            <el-option label='Fara' value='-1'></el-option>        
                            <el-option v-for='item in Info.categorii_roti' :key="'categorii_roti' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>                
                <el-col :xs="12" :sm="6" :md="6" :lg="6">
                    <el-form-item label='SUV'>
                        <el-checkbox v-model='selectedObject.EsteSuv' true-label='1' false-label='0'> DA </el-checkbox>
                    </el-form-item> 
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6">
                    <el-form-item label='Autoutilitar' >
                        <el-checkbox v-model='selectedObject.EsteCamion' true-label='1' false-label='0'> DA </el-checkbox>
                    </el-form-item>
                </el-col> 
            </el-row>
                      
            <!--:TriggerFocus="triggerFocus"
            :forcedByIdMasina="forcedByIdMasina"-->

            <el-row :gutter="15">
                    <el-col :xs="24" :sm="12" :md="12" :lg="12">
                        <el-form-item label='Client' prop="IdClient">                           
                            <div class="input-group">  
                                <SelectClient 
                                    class="full-width input-group-input" 
                                    v-model="numeClient" 
                                    :NumeClient="numeClient" 
                                    :IdClient.sync="selectedObject.IdClient" 
                                    :arataInfo="true"
                                    @selectie="select_client" />                                                     
                                <div class="input-group-append">
                                    <el-button  plain type="success" icon="el-icon-plus" @click="adaugaClient"></el-button>
                                </div>
                            </div>      
                        </el-form-item>
                    </el-col>                   
                <el-col :xs="24" :sm="12" :md="12" :lg="12">
                    <el-form-item label='Kilometraj' prop="UltimulKilometraj">
                        <el-input v-model="selectedObject.UltimulKilometraj"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>                                   
           
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="validare('my-form')" > Salveaza </el-button>
        </span>
          <Clienti-dialog ref='clienti-dlg' @save="refresh_clienti()" />
    </el-dialog>

</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import Clienti_dialog from '@/pages/clienti/Clienti_dialog.vue';
    import SelectClient from '@/components/SelectClient'

    export default {
        name: "Masini_dialog",
        extends: BasePage,
        components: {
            'Clienti-dialog': Clienti_dialog,
            SelectClient
        },
        data () {
            return {
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: { Numar: '' , IdClient: '' , IdCategorieRoata: '-1' , EsteSuv: '' , EsteCamion: '', Marca:'', UltimulKilometraj:'' },    
                selectedObject: { }                ,
                Info:{ clienti: [], categorii_roti: [] },                    
                rules: {
                    Numar: [ { required: true, message: 'Campul este obligatoriu' },
                             { required: true, pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, message: 'Sunt permise doar caractere alfanumerice', trigger: 'blur' }
                            ], 
                    IdClient: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdCategorieRoata: [ { required: true, message: 'Campul este obligatoriu' } ],                     
                    Marca: [ { required: true, message: 'Campul este obligatoriu' } ],                     
                },
                numeClient:''
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate))
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("masini/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                }

                this.get_info();
            },
            async get_info(){
                var response                = await this.post("masini/get_info_for_dialog" );                
                this.Info.categorii_roti    = response.categorii_roti;            
            },
             adaugaClient(){
              this.$refs['clienti-dlg'].show_me();
          },
           async refresh_clienti(){
              this.get_info();
              var response                  = await this.post('fisa_intrare/get_last_client', {})                         
              this.selectedObject.IdClient  = response.LastClient.Id
              this.numeClient               = response.LastClient.Nume
          },
            async validare() {                              
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        this.selectedObject.Numar = this.selectedObject.Numar.toUpperCase();                    

                        if(this.mode == 'add'){
                            let response = await this.post("masini/cauta_numar_masina", {numar_masina: this.selectedObject.Numar})
                            if(response.NumarMasini > 0) {
                                this.$alert('Numarul masinii este deja folosit!', 'Atentionare', {
                                    confirmButtonText: 'OK',
                                    callback: action => {
                                    //fac nimic
                                    }
                                });
                            } else 
                                this.save()                                                     
                        }
                        else    
                            this.save()                        
                    }
                    else 
                        console.log('eroare validare formular!')
                });
            },
            async save(){
                await this.post("masini/save", { mode: this.mode, object: this.selectedObject } );
                this.resetForm()
                this.$emit("save");
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
            },
            select_client(item){
                this.numeClient = item.Nume
            },
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

    .input-group
    {
        position:relative;
        display:flex;
        flex-wrap:wrap;
        -webkit-box-align: stretch;
        align-items: stretch;
        width:100%;

        .input-group-input
        {
            position:relative;
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            width: 1%;
        }

        .input-group-append
        {
            margin-left: -1px;
            display: flex;
        }
    }

</style>